/*
**  Author        : Chafik Amraoui
**  Dtae          : 09/10/2021
**  website       : Github.com/chafikamr
**  design source : https://elzero.org/frontend-google-colors-animation/
*/


/* global rules */

*,
*::before,
*::after {
    box-sizing: border-box;
}


/*End Global Rules**/

.login_loading {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrapper-parent {
    width: 300px;
    height: 80px;
    border-radius: 50px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.wrapper-parent .colors-container {
    display: flex;
    flex-wrap: wrap;
    width: 48px;
    height: 48px;
    transform: scale(6.5) rotate(0deg);
    animation: animation 5s infinite linear;
}

@keyframes animation {
    form {
        transform: scale(6.5) rotate(0deg);
    }
    to {
        transform: scale(6.5) rotate(360deg);
    }
}

.wrapper-parent .colors-container span {
    border: 12px solid;
    width: 50%;
}

.wrapper-parent span:nth-child(1) {
    border-color: #ffeb3b #ffeb3b #2196f3 #2196f3;
}

.wrapper-parent span:nth-child(2) {
    border-color: #8bc34a #f44336 #f44336 #8bc34a;
}

.wrapper-parent span:nth-child(3) {
    border-color: #f44336 #8bc34a #8bc34a #f44336;
}

.wrapper-parent span:nth-child(4) {
    border-color: #2196f3 #2196f3 #ffeb3b #ffeb3b;
}

.login_loading_text {
    z-index: 4;
    position: absolute;
    background-color: rgb(255, 255, 255);
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    color: #2196F3;
}