/** @format */

body {
  margin: 0;
  padding: 0;
  background-color: #f8f8fb;
  font-family: Calibri, Roboto, Helvetica, Arial, sans-serif !important;
}

.table-row-hover:hover {
  background: rgba($color: #000000, $alpha: 0.1);
  box-shadow: 0 5px 5px 0 rgba($color: #000000, $alpha: 0.1);
}

// this style for some action buttons on the table right part of table
.crud_actions_table {
  min-width: 70px;
  max-width: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  & > svg {
    cursor: pointer;
  }

  .delete_btn {
    color: #ff4d4f;
    font-size: 14px;
    margin: 0 6px;
    &:hover {
      color: #f5222d;
    }
  }
  .view_btn {
    color: #595959;
    font-size: 16px;
    margin: 0 6px;
    &:hover {
      color: #262626;
    }
  }
  .edit_btn {
    color: #595959;
    font-size: 16px;
    margin: 0 6px;
    &:hover {
      color: #262626;
    }
  }
}

// link title style
.fio_link {
  text-decoration: none;
  // color: #4B4E52;
  // color: #000;
  transition: 0.1s;
  text-transform: uppercase;
  // font-family: "Product Sans",Arial,sans-serif !important;
  // font-size: 12px !important;
  // font-weight: 450 !important;
  cursor: pointer;
  &:hover {
      color: #0d83fb;
      text-decoration: underline;
  }
}

// this style for different pages of content for example like this ( <- Page Name )
.pageContentTitle {
  display: flex;
  align-items: center;
  margin-top: 20px;
  & > span {
    font-size: 18px;
    font-weight: 500;
    margin-left: 16px;
    display: block;
  }

  & > svg {
    font-size: 16px;
    cursor: pointer;
    color: #595959;

    &:hover {
      color: #262626;
    }
  }
}
.pageContentTitleStudent {
  display: flex;
  align-items: center;
  & > span {
    font-size: 15px;
    font-weight: 400;
    margin-left: 8px;
    display: block;
  }

  & > svg {
    font-size: 22px;
    cursor: pointer;
    color: #595959;

    &:hover {
      color: #1890ff;
    }
  }
}

// this style for avatar on the table

.custom_vatar_box {
  width: 26px;
  height: 26px;
  border-radius: 50%;

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
  }
}

.custom_user_img_detail {
  width: 100%;
  height: 400px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  & > span {
    vertical-align: middle;
  }

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 786px) {
  .ant-tooltip {
    display: none !important;
  }
}

.loader-line {
  width: 100%;
  height: 2px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  margin: 0 auto;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 3px;
  width: 40%;
  background-color: #096dd9;
  -webkit-animation: lineAnim 1s linear infinite;
  -moz-animation: lineAnim 1s linear infinite;
  animation: lineAnim 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}

.goBackBtn {
  font-size: 15px;
  margin-right: 14px;
  color: #595959;
  cursor: pointer;
  margin-bottom: 2px;
  &:hover {
    color: #096dd9;
  }
}

// <-------======styles for Form =====---------->

.form_ui_flex {
  display: flex;
  @media (max-width: 576px) {
    display: block;
  }
}

.form_ui_flex_form_item {
  width: calc(100% - 150px);
  @media (max-width: 576px) {
    width: 100%;
  }
}
.ant-modal-body {
  .ant-upload {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

//  collapse style
.site-collapse-custom-collapse {
  background-color:#F7F9FB !important;
  padding: 20px 10px 1px 10px;
}

.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 16px;
  overflow: hidden;
  background: white;
  // border: 0px;
  border-radius: 4px;
  font-size: 14px;
  box-shadow: rgba(214, 217, 226, 0.2) 0px 8px 30px 0;
}
