

.profile_info_text_key {
    font-weight: bold;
    color:#7E838A;
    font-size: 13px;
}

.ant_input_custom[disabled] {
    font-weight: bold;
    color:#030303;
    font-size: 12px;
    background-color: #FCFCFC;
    border-color: #f0f0f0;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
}

.line_title {
    color:#1967D2 !important;
    font-size: 15px !important;
}