.subject_timetable_box {
    max-height: 700px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        background-color: transparent;
    }

    &::-webkit-scrollbar-button {
        background-color: green;
        color: white !important;
    }
    &::-webkit-scrollbar-button::after {
        background-color: green;
        color: white !important;
    }
}

// tt -> time table
.tt_name {
    font-size: 13px;
    font-weight: 450;
    display: block;
    word-wrap: break-word;
}
.tt_teacher {
    font-size: 13px;
    font-weight: 400;
    display: block;
    overflow-wrap: break-word;
    margin-bottom: 4px !important;
}

.tt_building_room {
    font-size: 13px;
    font-weight: 400;
    display: block;
    overflow-wrap: break-word;
}

.modal_light {
    background-color: #f8f7fa;
    color: #717171;
    padding: 0;

    .ant-modal-content {
        border-radius: 6px !important;

        button {
            color: #717171;
            & > span {
                width: 40px;
                height: 40px;
                line-height: 40px;
                font-size: 13px;
            }
        }
    }

    .ant-modal-header {
        background-color: #f8f7fa !important;
        border-bottom: 1px solid #d7d6d8;
        color: #717171;
    }
    .ant-modal-body {
        background-color: #f8f7fa !important;
        border-bottom: none;
        color: #717171;
    }
    .ant-modal-footer {
        background-color: #f8f7fa !important;
        border-top: none;
        color: #717171;
    }
}

.modal_dark {
    background-color: #17212b;
    color: #6c7883;
    padding: 0;

    .ant-modal-content {
        border-radius: 6px !important;

        button {
            & > span {
                width: 40px;
                height: 40px;
                line-height: 40px;
                font-size: 13px;
                color: #6c7883;
            }
        }
    }

    .ant-modal-header {
        background-color: #17212b !important;
        border-bottom: 1px solid #323d49;

        .ant-modal-title {
            color: #6c7883;
            font-weight: 400;
        }
    }
    .ant-modal-body {
        background-color: #17212b !important;
        border-bottom: none;
        color: #6c7883;
    }
    .ant-modal-footer {
        background-color: #17212b !important;
        border-top: none;
        color: #6c7883;
    }
}

.ant-checkbox-inner {
    width: 14px;
    height: 14px;

    &::after {
        width: 4px;
        height: 8px;
    }
}
.ant-checkbox {
    font-size: 10px;
}
.ant-checkbox + span {
    font-size: 13px;
    color: #000;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 2px;
}

.subject_type_timetable {
    color: #434343 !important;
    font-weight: 450;
    font-size: 13px;
    display: block;
    padding: 7px 0 6px 0 !important;
    text-align: center;
  }
  