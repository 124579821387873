.field_set_box {
  border: 1px solid #e3e8ef !important;
  padding: 0 8px 6px 8px !important;
  border-radius: 4px;

  & > legend {
      float: none;
      padding: 0 6px;
      color: #cf6100;
      // color: #1890FF;
      // color: #c26c03;
      border: none;
      margin: 0;
      font-size: 15px;
      font-weight: 500;
  }

  & > div {
      color: #73787e;
      font-size: 13px;
      text-shadow: 0 0 #73787e;
      letter-spacing: 0.4px;
  }
}

.upload_btn{
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 0.5rem;
}

.ifream_style{
  width: 100%;
  border-radius: 1rem;
  height: 600px;
  // margin: 1rem 0;
  border: 1px solid #e3e8ef;
}

.question-color{
  color: #cf6100;
}

.answer-color{
  color: #40AD5D !important;
}

.appeal-modal .ant-modal-body{
  padding: 1rem;
}


// status style
.status-exam-control{
  display: inline-block;
  margin: .25rem 0 .25rem .5rem;
  padding: .15rem .5rem;
  border-radius: .125rem;
  font-size: 13px;
  // cursor: pointer;

  .question{
    color: #1A87EE;
  }
  .bold{
    font-weight: 500;
  }
}
.status-exam-control._14{
font-size: 14px;
}
.status-exam-control._15{
font-size: 15px;
}
.status-exam-control.bold{
font-weight: 500;
}
.status-exam-control.green{
// background-color: #E6F4EA;
// color: #0c8634
background-color: #E6F4EA;
color: #129c37
}
.status-exam-control.red{
background-color: #FBE7E5;
color: #d61d1d
}
.status-exam-control.orange{
background-color: #fff1e1;
color: #db8b22
}
.status-exam-control.blue{
background-color: #E8F0FE;
color: #1A87EE
}