.create_custom_btn {
    border: 1px solid #f1f0f3;
    border-radius: 10px;
    min-width: 100px;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 0;
    background-color: white;
    height: 24px;
    color: #1890ff;

    &:active {
        background-color: #f8f7fa;
    }

    & > span {
        height: 24px;
        width: 24px;
        background-color: #f8f7fa;
        border-radius: 50%;
        display: block;
        position: relative;
        margin-left: 10px;
        & > svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.semester_btn {
    border: none;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #1890ff;
    font-size: 12px;
    color: white;
}

.notice_subjects_type {
    background-color: #f8f7fa;
    border-radius: .3rem;
    padding: .25rem .5rem;
    margin-top: .5rem;

    span:nth-child(1) {
        font-size: 15px;
        color: black;
        font-weight: 450;
    }
}

.list_subject {
    border-radius: 8px;
    margin-top: 16px;
    padding: 10px 8px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    & > div {
        display: flex;
    }

    @media (max-width: 786px) {
        display: block;

        & > div {
            margin-top: 14px;
            display: flex;
            justify-content: center !important;
        }
    }
}

.std_subject_header {
    display: flex;
    justify-content: space-between;
    align-items:center;

    @media (max-width: 600px) {
        display: block;
        & > :nth-child(2) {
            width: 100%;
            text-align: end;
        }
    }
}



// ---------------  style of Subject cart  --------------

.subject_cart{
    background-color: #1890ff;
}

.subject_header{
    margin: .25rem 0;
    p{
        font-size: 13px;
        margin: .25rem 0;
    }

}
.cards.w-100{
    margin: .5rem 18px;
    .ant-card-body{
        padding: 0;
        .ant-card-body{
            padding: .5rem;
        }
    }
    .button{
        span{
            color: #fff
        }
    }
}


.subject-grid{
    display: grid;
    grid-template-columns: 60px auto auto auto auto;
    gap: .5rem 0;

    .teacher{
        text-transform: capitalize;
    }

    .header{
        width: 100%;
        height: 100%;
        min-height: 2.4rem;
        background-color: #F1F4F9;
        // color: coral;
        font-weight: 500;
        display: flex;
        align-items: center;
        padding: .25rem .5rem;
        // border-radius: .5rem;
        p, h1, h5, h6{
            padding: 0;
            margin: 0;
        }

        &:first-child{
            border-radius: .75rem 0 0 .4rem;
            padding-left: 1rem;
        }
        &:nth-child(5){
            border-radius: 0 .75rem .4rem 0;
            padding-right: 1rem;
        }
    }

    .column {
        width: 100%;
        height: 100%;
        min-height: 3.6rem;
        background-color: #F1F4F9;
        display: flex;
        align-items: center;
        padding: .25rem .5rem;
        // border-radius: .5rem;
        p, h1, h5, h6{
            padding: 0;
            margin: 0;
        }

        .subject-btn{
            background-color: #E7EDF5;
            border-radius: .5rem;
            border: 1px solid #ccd0db;
            color: #7a7f88;

            &:hover{
                color: #1890ff;
                border-color: #1890ff;
            }
        }

        &:nth-child(5n+1), &:first-child{
            border-radius: .4rem 0 0 .4rem;
            padding-left: 1rem;
        }
        &:nth-child(5n){
            border-radius: 0 .4rem .4rem 0;
            padding-right: 1rem;
        }
    }
}
