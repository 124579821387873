
.page_title {
    font-size: 15px;
    color:#5F6368;
    font-weight:400;

    svg {
        
        margin-right: 10px;

        &:hover {
            cursor:pointer;
            color:blue;
        }
    }
}