.list_item_box {
    margin: 20px 0;
    padding: 10px 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    border-radius: 4px;
}

.notice_box {
    background-color: #e8f0fe;
    color: #1967d2;
    width: 100%;
    padding: 10px;
    border-radius: 4px;

    svg {
        font-size: 20px;
        font-weight: 400 !important;
    }

    p {
        font-weight: 400;
        font-size: 15px;
    }
}
.petition_box {
    background-color: #f1f3f4;
    color: #5f6368;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    p {
        font-weight: 400;
        font-size: 15px;
    }
}

.title_petition {
    font-size: 14px;
    font-weight: 500;
    color: #5f6368;
}

.status_tag {
    min-width: 200px;
    text-align: center;
    padding: 5px 10px;
    font-size: 14px;

    @media (max-width: 760px) {
        width: 100%;
    }
}
.status_tag_answer {
    min-width: 200px;
    text-align: center;
    padding: 0px 6px;
    font-size: 13px;

    @media (max-width: 760px) {
        width: 100%;
    }
}
