.page-notice {
    background-color: #f5f7f9;
    font-size: 13px;
    border-radius: 4px;
    padding: 8px 10px;
    font-weight: 400;
    color: #5f6368;
    .notice_title {
        font-weight: bold;
        color: #c26c03;
    }
}
.page-info-container {
    background-color: #f5f7f9;
    font-size: 14px;
    border-radius: 4px;
    padding: 8px 10px;
    font-weight: 500;
    color: #5f6368;
}
