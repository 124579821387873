.statute_warning {
    color: #575b68;
    text-shadow: 0 0 #575b68;
    letter-spacing: 0.2px;

    @media (max-width: 786px) {
        font-size: 13px;
        text-align: justify;
        display: block;
    }

    span {
        font-size: 18px;
        margin-right: 10px;
        color: #d6d5d8;
    }

    .statute_sign_out {
        border: none;
        text-align: end;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
        color: red;
        background-color: transparent;
    }
}
.statute_last_warning {
    color: #ff6723;
    text-shadow: 0 0 #ff6723;
    letter-spacing: 0.2px;
    @media (max-width: 786px) {
        font-size: 13px;
        text-align: justify;
        display: block;
    }
}

.statute_name {
    color: #575b68;
    text-shadow: 0 0 #575b68;
    letter-spacing: 0.2px;
    vertical-align: middle;
    display: flex;
    align-items: center;

    @media (max-width: 786px) {
        font-size: 13px;
    }

    svg {
        font-size: 18px;
        margin-right: 10px;
        color: #d6d5d8;

        @media (max-width: 786px) {
            display: none;
        }
    }
}
.statute_link {
    color: #0d6efd;
    text-shadow: 0 0 #0d6efd;
    letter-spacing: 0.2px;
    font-size: 14px;

    @media (max-width: 786px) {
        font-size: 12px;
    }

    svg {
        margin-left: 10px;
    }
}

.statute_download {
    border: none;
    background-color: #f0eff4;
    color: #40427f;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: inline-block;
    position: relative;

    @media (max-width: 786px) {
        width: 24px;
        height: 24px;
    }

    &:hover {
        color: #40427f;
    }

    &:active {
        background-color: #ddd7f4;
    }
    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 18px;
        @media (max-width: 786px) {
            font-size: 16px;
        }
    }
}

.statute_confirm_btn {
    border: none;
    height: 30px;
    font-size: 13px;
    border-radius: 4px;
    width: 120px;
    user-select: none;

    @media (max-width: 786px) {
        font-size: 12px;
        height: 24px;
        width: 120px;
        background-color: white;
    }
}

.statute_save_btn {
    border: none;
    background-color: #40427f;
    color: white;
    height: 30px;
    display: inline-block;
    position: relative;
    font-size: 14px;
    border-radius: 4px;
    user-select: none;
    width: 120px;
    @media (max-width: 786px) {
        width: 100% !important;
        margin-bottom: 20px;
    }

    &:disabled {
        pointer-events: none;
        background-color: #8d8eb2;
    }
}

.statute_container_hompage {
    padding: 0 8px 0 18px;
    @media (max-width: 786px) {
        margin-top: 16px;
        padding: 0;
    }
    .statute_box {
        border: 1px solid #efedf5;
        border-radius: 4px;
        padding: 4px 8px 2px 8px;
        display: flex;
        align-items: center;
        @media (max-width: 786px) {
            display: block;
        }

        .statute_name {
            color: #898b97;
            letter-spacing: 0.3px;
            font-size: 12px;
            text-transform: uppercase;
            width: 80%;
            display: inline-block;

            @media (max-width: 786px) {
                font-size: 11px;
                text-align: justify;
                border-bottom: 1px solid #efedf5;
                padding: 4px 0;
                display: block;
                width: 100%;
            }
        }

        .statute_actions_homepage {
            width: 20%;
            display: inline-block;
            text-align: end;
            @media (max-width: 786px) {
                width: 100%;
                text-align: end;
                display: block;
            }
        }
    }
}
