.sidebar_avatar {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    & > img {
        width: 100%;
        height: 100%;
    }
}