.student_club_card {
    display: flex;

    @media (max-width: 786px) {
        display: block !important;
    }
    .student_club_card_image {
        border: 4px solid #f1f4f9;
        border-radius: 10px;
        width: 70px;
        height: 70px;

        img {
            width: 100%;
            height: 100%;
            border-radius: 6px;
            object-fit: cover;
        }
    }
    .student_club_card_info {
        height: 100%;
        width: calc(100% - 70px);
        margin-left: 10px;
        & > p {
            display: block;
            width: 100%;
            color: #5f6368;
            font-weight: 500;
            height: 100%;

            span {
                display: block;
                font-size: 13px;
                color: #1890ff;
                font-weight: 400;
                margin-top: 4px;
            }
        }
    }

    @media (max-width: 786px) {
        .student_club_card_image {
            border: 4px solid #f1f4f9;
            border-radius: 10px;
            width: 120px;
            height: 120px;
            margin: 0 auto;

            img {
                width: 100%;
                height: 100%;
            }
        }
        .student_club_card_info {
            width: 100%;
            margin-left: 0;
            & > p {
                margin-top: 1rem;
                text-align: center;

                span {
                    text-align: justify;
                    margin-top: 6px;
                }
            }
        }
    }
}

.student_club_times {
    width: 100%;
    padding: 2px 10px;
    border-radius: 4px;
    border: 1px solid #f0f3f8;
    color: #c26c03;
    font-weight: bold;
    font-size: 13px;
    display: block;
    margin-top: 3px;
}

.student_club_remove_btn {
    position: absolute;
    top: -4px;
    right: -4px;
    background-color: #fce8e6;
    display: block;
    width: 20px;
    height: 20px;
    border: 2px solid #ed161e;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.club_category_card {
    position: relative;
    & > span {
        color: #1890ff;
        display: block;
        padding-bottom: 4px;
        font-size: 16px;
    }

    p {
        color: grey;
        font-size: 13px;
        border-top: 1px solid #f1f3f6;
        margin-top: 4px;
        padding-top: 6px;
        border-radius: 4px;
    }

    .clubs_link {
        position: absolute;
        bottom: -10px;
        right: 0;
        font-size: 13px;
    }
}
.club_selection_card {
    display: flex;

    @media (max-width: 786px) {
        display: block !important;
    }
    .student_club_card_image {
        border: 4px solid #f1f4f9;
        border-radius: 10px;
        width: 124px;
        height: 124px;

        img {
            width: 100%;
            height: 100%;
            border-radius: 6px;
        }
    }
    .student_club_card_info {
        height: 100%;
        width: calc(100% - 70px);
        margin-left: 10px;
        & > p {
            display: block;
            width: 100%;
            color: #5f6368;
            font-weight: 500;
            height: 100%;
            font-size: 14px;

            span {
                display: block;
                font-size: 13px;
                color: #1890ff;
                font-weight: 400;
                margin-top: 4px;
            }
        }
    }

    .club_time_card {
        border: 1px solid #e8eaed;
        padding: 8px 8px 8px 8px;
        border-radius: 4px;
        margin-top: 10px;
        .club_time {
            background-color: transparentize($color: #c26c03, $amount: 0.9);
            border-radius: 4px;
            padding: 2px 8px;
            font-weight: 500;
            margin-top: 4px;
            color: #c26c03;
            font-size: 13px;
        }
        .subscribe_club {
            background-color: white;
            position: absolute;
            top: -4px;
            right: -4px;
            border-radius: 4px;
        }
    }

    @media (max-width: 786px) {
        .student_club_card_image {
            border: 4px solid #f1f4f9;
            border-radius: 10px;
            width: 120px;
            height: 120px;
            margin: 0 auto;

            img {
                width: 100%;
                height: 100%;
            }
        }
        .student_club_card_info {
            width: 100%;
            margin-left: 0;
            & > p {
                margin-top: 1rem;
                text-align: center;

                span {
                    text-align: justify;
                    margin-top: 6px;
                }
            }
        }
    }

}


// style for club page

.club{
    color: #5f6368;

    .club_header{
        width: 100%;
        display: flex;

        .club_image{
            border: 4px solid #f1f4f9;
            border-radius: 10px;
            padding: 2px;
            width: 200px;
            height: 200px;

            img {
                width: 100%;
                height: 100%;
                border-radius: 6px;
            }
        }
        .club_info {
            margin-left: 1.5rem;
            & > p {
                color: #4e5257;
                font-weight: 500;
                font-size: 18px;
            }
        }
    }
    .divider_style{
        color: #5f6368;
        font-weight: 500;
    }

    .club_time_card {
        border: 1px solid #e8eaed;
        padding: 8px 8px 8px 8px;
        border-radius: 4px;
        margin-top: 10px;
        .club_time {
            background-color: transparentize($color: #c26c03, $amount: 0.9);
            border-radius: 4px;
            padding: 2px 8px;
            font-weight: 500;
            margin-top: 4px;
            color: #c26c03;
            font-size: 13px;
        }
        .subscribe_club {
            background-color: white;
            position: absolute;
            top: -4px;
            right: -4px;
            border-radius: 4px;
        }
    }

    @media (max-width: 786px) {
        .club_header{
            display: block;

            .club_image{
                margin: 0 auto;
            }
            .club_info{
                width: 100%;
                margin: 0;
                text-align: center;
                margin-top: 1.5rem;
            }
        }
    }
}
