.table-row-cursor-pointer {
  cursor: pointer;
}

.exam-question-item {
  // background-color: #8c8c8c;
  cursor: pointer;
  color: #fff;

  &:hover {}
}

.exam-question-in-window {
  word-break: break-all !important;
}

.question-container-style {
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
}

// #containerLeft {
//     position: absolute;
//     transform: translateX(-100%);
//     height: 550px;
//     overflow: auto;
// }
// #containerRight {
//     transform: translateX(200%);
// }
// .slide-in {
//     animation: slide-in 0.5s forwards;
//     -webkit-animation: slide-in 0.5s forwards;
// }
// .slide-out {
//     animation: slide-out 0.5s forwards;
//     -webkit-animation: slide-out 0.5s forwards;
// }
// @keyframes slide-in {
//     100% {
//         transform: translateX(0%);
//     }
// }
// @-webkit-keyframes slide-in {
//     100% {
//         -webkit-transform: translateX(0%);
//     }
// }
// @keyframes slide-out {
//     0% {
//         transform: translateX(0%);
//     }
//     100% {
//         transform: translateX(-110%);
//     }
// }
// @-webkit-keyframes slide-out {
//     0% {
//         -webkit-transform: translateX(0%);
//     }
//     100% {
//         -webkit-transform: translateX(-100%);
//     }
// }
// .slide-in1 {
//     animation: slide-in1 0.5s forwards;
//     -webkit-animation: slide-in1 0.5s forwards;
// }
// .slide-out1 {
//     animation: slide-out1 0.5s forwards;
//     -webkit-animation: slide-out1 0.5s forwards;
// }
// @keyframes slide-in1 {
//     100% {
//         transform: translateX(0%);
//     }
// }
// @-webkit-keyframes slide-in1 {
//     100% {
//         -webkit-transform: translateX(0%);
//     }
// }
// @keyframes slide-out1 {
//     0% {
//         transform: translateX(0%);
//     }
//     100% {
//         transform: translateX(200%);
//     }
// }
// @-webkit-keyframes slide-out1 {
//     0% {
//         -webkit-transform: translateX(0%);
//     }
//     100% {
//         -webkit-transform: translateX(200%);
//     }
// }


.se-wrapper-inner {

  &>p,
  span,
  strong,
  div,
  i,
  u,
  b {
    font-size: 16px;
    font-family: Rubik, Helvetica, Arial, serif;
  }
}


.sub-question-text-style {

  &>span,
  p,
  div,
  b,
  i,
  u {
    font-size: 16px !important;
  }
}

.result-exam-answer {

  table,
  td {
    border: 1px solid #e1e1e1 !important;
  }
}


.site-collapse-custom-collapse {
  background-color:#F6F6F6;
  padding: 20px 10px 1px 10px;
}


[data-theme='compact'] .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
margin-bottom: 16px;
overflow: hidden;
background: white;
border: 0px;
border-radius: 2px;
font-size: 14px;
}
