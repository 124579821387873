.std_subject_header {
  display: flex;
  justify-content: space-between;
  align-items:center;

  @media (max-width: 600px) {
      display: block;
      & > :nth-child(2) {
          width: 100%;
          text-align: end;
      }
  }
}