.list_item {
    background-color: #e8f0fe;
    color: #1967d2;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;

    &:hover {
        background-color:#D6E5FE;
    }

    svg {
        margin-right: 10px;
    }

}
