.page_information_papper {
    width: 60%;
    height: 800px;
    margin: 0 auto;
    padding: 20px 18px;
    -webkit-box-shadow: 0px 0px 14px -2px rgba(4, 4, 4, 0.2);
    -moz-box-shadow: 0px 0px 14px -2px rgba(4, 4, 4, 0.2);
    box-shadow: 0px 0px 14px -2px rgba(4, 4, 4, 0.2);

    @media (max-width: 768px) {
        width: 100%;
        font-size: 12px;
        padding: 0.75rem 0.5rem;
    }
}