.student_profile {
    min-height: 100vh;
    background-color: #0e1621;

    @media (max-width:1500px){
        padding-top: 0 !important;
    }
}

main {
    &::-webkit-scrollbar {
        display: none!important;
    }
}

.secondAside {
    &::-webkit-scrollbar {
        display: none!important;
    }
}

.wrapper {
    width: 100%;
    margin: 0 auto;

    @media (max-width: 1700px) {
        // width: 90%;
    }
    @media (max-width: 1500px) {
        width: 100%;
    }
}


.sidebar_content {
    overflow-x: hidden;
}

.forCalendar {
    & td {
        position: relative;
        &:nth-child(1) {
            & .ant-picker-calendar-date-content {
                left: 0;
                right: unset;
            }
        }
        & .ant-picker-calendar-date-content {
            position: absolute!important;
            top: 0;
            right: 0;
            opacity: 0;
            z-index: 100;
            background-color: #fff;
            transition: all .2s;
                & ul {
                    padding-left: 0;
                    & li {
                        list-style: none;
                    }
                    &:not(.hasContent) {
                        margin-bottom: 0;
                    }
                }
        }
        &:hover {
            & .ant-picker-calendar-date-content {
                opacity: 1;
            }
        }
    }
}

.forCalendar .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    z-index: 99;
}

.layoutSider {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}