.custom_modal {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

