// @import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
* {
    margin: 0px;
    // font-family: 'PT Sans', sans-serif;
}
.container_teacher_profile {
    // margin: 0 3rem;
    & .header {
        & h1 {
            font-size: 2.4rem;
            line-height: 2.4rem;
            // padding: 1rem 0;
            & a {
                color: #5F8DF8;
                border-bottom: none;
                text-decoration: none;
                display: inline-block;
            }
        }
    }
    & .body {
        & .aside {
            & ul {
                padding: 0;
                margin: 0;
                width: 100%;
                & li {
                    list-style: none;
                    padding: .6rem 1rem;
                    background-color: #f5f5f5;
                    border-left: 8px solid #5F8DF8;
                    &.active {
                        background-color: #5F8DF8;
                        color: #fff;
                    }
                }
            }
            & .search {
                & li {
                    padding-left: 3rem;
                    position: relative;
                    border-left: none;
                    background-color: #F1F4F9;
                    &:hover::before {
                        left: 18px
                    }
                    &::before {
                        content: "";
                        display: block;
                        margin-left: 0.3125em;
                        width: 1.25rem;
                        height: 1.25rem;
                        position: absolute;
                        top: 11px;
                        left: 14px;
                        background-image: url('https://www.gla.ac.uk/3t4/img/arrow-thin-blue.svg');
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: 75%;
                        transition: left .3s;
                    }
                    & svg {
                        height: 20px;
                        width: 20px;
                        margin-right: 5px;
                        transition: all;
                    }
                    & a {
                        text-decoration: none;
                        color: #0f364a;
                    }
                    & > * {
                        vertical-align: middle;
                    }
                }
            }
        }
    }
    & .anketa {
        background-color: #F1F4F9;
        & .main {
            padding: 1rem;
            &>:last-child > div {
                background-color: #fff;
                padding: 1rem;
                margin: 1rem;
                border-bottom: 8px solid #5F8DF8;
                & p > img {
                    width: 100%;
                }
                & .text {
                    margin-top: 1rem;
                    padding: 1rem;
                    background-color: #F1F4F9;
                    & .twitterfeed {
                        & > a {
                            display: flex;
                            align-items: start;
                            justify-content: space-between;
                            & h2 {
                                color: #fff;
                                font-size: 1.4rem;
                                line-height: 1.4rem;
                                font-weight: 600;
                            }
                        }
                        & ul {
                            margin: 0;
                            padding: 0;
                            & li {
                                padding-bottom: 1rem;
                                margin-bottom: 1rem;
                                list-style: none;
                                & a {
                                    color: #fff;
                                    font-size: 1rem;
                                }
                            }
                            & li:not(:last-child) {
                                border-bottom: 1px solid #d3d3d3;
                            }
                        }
                    }
                    & .twitter_link {
                        & a {
                            color: #fff;
                            font-size: 1rem;
                        }
                    }
                }
            }
            & h1 {
                font-size: 2rem;
                line-height: 2rem;
                font-weight: 900;
                margin: 0.67em 0;
                text-transform: uppercase;
                margin-top: 1rem;
                margin-bottom: 1rem;
                color: #5F8DF8;
            }
            & ul.underTitle {
                margin: 1rem 0;
                & li {
                    padding: 0.4rem;
                    list-style: square;
                    font-size: 1.1rem;
                }
            }
            & #contactInfo {
                & p {
                    font-size: 1.1rem;
                    margin: 1rem 0;
                    & a {
                        color: #5F8DF8;
                        & img {
                            width: 16px;
                            margin: 0 4px;
                            vertical-align: middle;
                        }
                    }
                }
            }
            & .ant-collapse {
                border: none;
                & .ant-collapse-item {
                    margin: 1rem 0;
                    & .ant-collapse-header {
                        border-radius: .25rem;
                        background-color: #5F8DF8 !important;
                        color: #fff;
                        & .ant-collapse-expand-icon {
                            margin-right: .4rem;
                            & svg {
                                width: 100%;
                                height: 100%;
                                vertical-align: middle;
                            }
                        }
                        & .ant-collapse-header-text {
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1199px) {
    .aside {
        display: none!important;
    }
    .main > :last-child {
        order: 0;
    }
    .main > :first-child {
        order: 5;
    }
}